<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ESFJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Kepribadian ESFJ biasa juga disebut the caregiver atau mereka yang senang memberi, di dunia ini masih ada 9-13% pribadi ESFJ di dunia, dan masih bisa dikatakan cukup banyak. Dalam dimensinya ESFJ berarti Extroversion, dengan pribadi yang terbuka dan senang dalam berbagi apapun dan memiliki banyak teman. Sensing, dimana mereka menggunakan  hal yang konkret dibandingkan hal abstrak atau tidak jelas. Feeling, merupakan pribadi ESFJ dimana mereka menggunakan perasaan pribadi dalam menentukan keputusan, serta Judging. Yakni mereka yang lebih suka membuat perencanaan terlebih dahulu baru menjalankannya. 
          </p>
          <p>
            Ciri kepribadian ESFJ adalah:
            <ul>
              <li> Sangat loyal, teroganisir dan juga bisa diandalkan.</li>
              <li> Anak teladan, menciptakan hal yang damai dan rapih serta structural dan tidak melenceng.</li>
              <li> Cenderung menempatkan kebutuhan orang lain dan sangat baik dalam memberikan perawatan praktis.</li>
              <li> Tidak suka berteori tentang masa depan dan memikirkan hal yang harus dipikirkan saat ini.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Jangan mengorbankan diri sendiri terus, anda juga harus memikirkan kebutuhan pribadi.
              </li>
              <li>
                Jangan mengukur harga diri dari perlakuan dan pujian, karena bisa saja hal tersebut palsu adanya.
              </li>
              <li>
               Terima tanggung jawab hidup dan jangan menghindari konflik. Terkadang meskipun sudah diatur dengan baik anda tetap harus menghadapi masalah.
              </li>
              <li>
                Pekerjaan yang cocok adalah child care, perawat, pemerhati anak, bidang agama, akuntan, bidang usaha, dokter keluarga dan konsultan atau konseling keluarga.
              </li>
            </ul>
          </p>
          <p>
            Hubungan ESFJ baiknya dengan pribadi yang ISFP atau INFP, dimana mereka sama-sama menggunakan perasaan dalam menentukan berbagai keputusan namun ada juga yang bertolak belakang untuk menyeimbangkan diri.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ESFJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ESFJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ESFJ',
        slug: 'esfj'
      }
    }
  }
};
</script>

<style scoped>

</style>
